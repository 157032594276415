import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  product: getIcon('ic_products'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  invoice: getIcon('ic_invoice'),
  category: getIcon('ic_category'),
  report: getIcon('ic_report'),
  setting: getIcon('ic_settings'),
  store: getIcon('ic_stores2'),
  task: getIcon('ic_task'),
  salesreport: getIcon('ic_salesreport'),
  cost: getIcon('ic_cost'),
  manufacture: getIcon('ic_manufacture'),
  inventory: getIcon('ic_inventory'),
  designmaster: getIcon('ic_designmaster')

};
const role = 2;

const getSidebarConfig = (
  manufactureCount = {},
  filteredDiamonds = {},
  filteredGemstones = {},
  filteredUnmount = {},
  filteredSetmount = {},
  filteredEarrings = {},
  filteredPendants = {},
  filteredBracelet = {}
) => {
  const sidebarConfig = [];

  // Check the user's role
  if (role === 2) {
    sidebarConfig.push(
      // MANAGEMENT
      {
        subheader: 'Management',
        items: [
          // DASHBOARD
          { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

          // INVOICE
          {
            title: 'Invoices',
            path: PATH_DASHBOARD.invoice.root,
            icon: ICONS.invoice
          },
          // MANUFACTURE
          {
            title: 'Manufacture',
            path: PATH_DASHBOARD.manufacture.root,
            icon: ICONS.manufacture,
            children: [
              { title: `Order (${manufactureCount.AllCount})`, path: PATH_DASHBOARD.manufacture.list },
              { title: `Remake (${manufactureCount.RemakeCount})`, path: PATH_DASHBOARD.manufacture.remakelist },
              { title: `Resize (${manufactureCount.ResizeCount})`, path: PATH_DASHBOARD.manufacture.resizelist },
              { title: `Repair (${manufactureCount.RepairCount})`, path: PATH_DASHBOARD.manufacture.repairlist }
            ]
          },

          // TASK
          {
            title: 'Tasks',
            path: PATH_DASHBOARD.task.root,
            icon: ICONS.task
          },
          // PRODUCT
          // {
          //   title: 'Products',
          //   path: PATH_DASHBOARD.product.root,
          //   icon: ICONS.product
          // },
          // COST
          {
            title: 'Costs',
            path: PATH_DASHBOARD.cost.root,
            icon: ICONS.cost
          },
          // INVENTORY
          {
            title: 'Inventory',
            path: PATH_DASHBOARD.inventory.root,
            icon: ICONS.inventory,
            children: [
              { title: `Diamond (${filteredDiamonds.length})`, path: PATH_DASHBOARD.inventory.diamond },
              { title: `Gemstone (${filteredGemstones.length})`, path: PATH_DASHBOARD.inventory.gemstone },
              { title: `Unset mount (${filteredUnmount.length})`, path: PATH_DASHBOARD.inventory.unmount },
              { title: `Set mount (${filteredSetmount.length})`, path: PATH_DASHBOARD.inventory.setmount },
              { title: `Earrings (${filteredEarrings.length})`, path: PATH_DASHBOARD.inventory.earrings },
              { title: `Pendants (${filteredPendants.length})`, path: PATH_DASHBOARD.inventory.pendants },
              { title: `Bracelet (${filteredBracelet.length})`, path: PATH_DASHBOARD.inventory.bracelet }
            ]
          },
          // STORE
          {
            title: 'Stores',
            path: PATH_DASHBOARD.store.root,
            icon: ICONS.store
          },
          // USER
          {
            title: 'Users',
            path: PATH_DASHBOARD.user.root,
            icon: ICONS.user
          },
          // DESIGN DETAIL
          {
            title: 'Design Master',
            path: PATH_DASHBOARD.designdetail.root,
            icon: ICONS.designmaster
          },

          // REPORTS
          {
            title: 'Reports',
            path: PATH_DASHBOARD.report.root,
            icon: ICONS.report
          },
          // SALES REPORTS
          {
            title: 'Sales Reports',
            path: PATH_DASHBOARD.salesreport.root,
            icon: ICONS.salesreport
          }
        ]
      }
    );
  } else {
    sidebarConfig.push({
      subheader: 'management',
      items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }]
    });
  }
  return sidebarConfig;
};

export default getSidebarConfig;
